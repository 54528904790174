import React, { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useAppSelector } from "../../../app/hooks"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import MuiLink from "@mui/material/Link"
import Badge from "@mui/material/Badge"
import InputLabel from "@mui/material/InputLabel"
import CustomTextField from "../../base/Textfield/CustomTextField"
import { SelectChangeEvent } from "@mui/material/Select"
import CustomSelect from "../../base/Select/CustomSelect"
import IconButton from "@mui/material/IconButton"
import MenuItem from "@mui/material/MenuItem"
import { getAccountState } from "../../../features/account/accountSlice"
import { useUpdateAccountMutation, useCompletionMutation } from "../../../features/account/accountRestApi"
import Button from "../../base/Button/Button"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import EditIcon from "../../base/Icons/EditIcon"
import { formatDate } from "../../../constants"
import Share from "../../Share/Share"
import Toast from "../../base/Toast/Toast"

interface BroadcastProps {
  handleStepper: (step: number) => void
}

const Broadcast: React.FC<BroadcastProps> = (props) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const accountState = useAppSelector(getAccountState)
  const { firstName, lastName } = accountState.details
  const [toast, setToast] = useState({
    type: "success",
    open: false,
    message: "",
    handleClose: () => {
      setToast((prev) => ({ ...prev, open: false }))
    }
  })

  const [updateCompletion] = useCompletionMutation()

  const onPrev = () => {
    // props.handleStepper(9)
    props.handleStepper(5)
  }

  const onDone = async () => {
    const name = lastName
    try {
      let body = {
        funeralId: accountState.details.id,
        path: `${name}/${accountState.details.id}`,
        isCompleted: accountState.details.isCompleted,
        query: {
          isCompleted: 1,
          step: 0
        }
      }
      let rv = await updateCompletion(body).unwrap()

      if (rv) {
        setToast({
          ...toast,
          open: true,
          type: "success",
          message: rv.message
        })

        navigate("/dashboard")
      }
    } catch (e: any) {
      setToast({
        ...toast,
        open: true,
        type: "error",
        message: e.data.message
      })
    }
  }

  return (
    <Box sx={{ pb: 4 }}>
      <Toast {...toast} />
      <Share account={accountState.details} eventlist={accountState.details.eventList} />

      <Grid container spacing={2} justifyContent="end" alignItems="center">
        {/* <Grid item xs={8} md={2.2} sx={{mt: 2}}>
          <Button className='btnPrimary' sx={{width: {xs: 250, sm: 270}, mb: 4}} onClick={onPrev}>Previous</Button>
        </Grid> */}
        <Grid item xs={8} md={2.2} sx={{ mt: 2 }}>
          <Button className="btnSecondary" sx={{ width: { xs: "100%", sm: 270 }, mb: 4 }} onClick={onDone}>
            Done
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Broadcast
